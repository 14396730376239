import React, { useState, useEffect, useMemo } from 'react';
import { QUERY_FILTER_BOARD_STATUS } from '../constants';
import { Link, useNavigate } from 'react-router-dom';
import countryList from 'react-select-country-list';
import { getResource } from '../services/farmApi';
import { useTranslation } from "react-i18next";
import { useUser, useEnv } from '../contexts';
import { useHandleSubmit } from '../hooks';
import { ConsoleLogEvent } from '../utils';
import Select from 'react-select'; 


const ProductNavbar = () => {

    const { t } = useTranslation();
    const { user } = useUser();  // Get currently loggedIn user
    const { envState, setEnvState } = useEnv(); // Get Environment context
    const [loading, setLoading] = useState(false); // Loading state
    const [reportData, setReportData] = useState([]);
    const [createNotice, setCreateNotice] = useState({ showNotice: false, succ: false, msg: "" });
    const [errorMessage, setErrorMessage] = useState(null); // Error message state
    const { handleGetResource } = useHandleSubmit(); // Shared submit handler
    const [boardStatus, setBoardStatus] = useState(false);
    const [currentPage, setCurrentPage] = useState('sale'); // Track the current page

    const navigate = useNavigate(); // Initialize useNavigate hook
    const forSaleDisplay = () => {
        handleShowSelect();
        setCurrentPage('sale'); // Indicate "For Sale" page is active
        navigate('', {replace: true, state: { country: countryValue }}); // Navigate to 'marketplace' without nesting
    };
    const forNeedDisplay = () => {
        handleShowSelect();
        setCurrentPage('need'); // Indicate "For Need" page is active
        navigate('supply-item', { state: { country: countryValue }});
    }

    const forKitDisplay = () => {
        handleShowSelect();
        setCurrentPage('kit'); // Indicate "For Sale" page is active
        navigate('list-kit', { state: { country: countryValue }});
    }

    const forCloseDeal = () => {
        setShowSelect(false);
        navigate('close-deal');
    }
    const forOpenBoard = () => {
        setShowSelect(false);
        navigate('open-board');
    }
    const forAddProduct = () => {
        setShowSelect(false);
        navigate('add-product');
    }
    const forRequestBuy = () => {
        setShowSelect(false);
        navigate('request-buy');
    }
    const forMarketPlaceChat = () => {
        setShowSelect(false);
        navigate('marketplace-chat');
    }

    // *********************** start country name selection *************************************

    const [showSelect, setShowSelect] = useState(false);
    const [countryValue, setCountryValue] = useState('');
    const allCountries = useMemo(() => countryList().getData(), []);
    const prioritizedCountries = [
      { label: 'Tanzania', value: 'TZ' },
      { label: 'Kenya', value: 'KE' },
      { label: 'Rwanda', value: 'RW' },
      { label: 'Uganda', value: 'UG' },
      { label: 'Burundi', value: 'BI' },
      { label: 'Zambia', value: 'ZM' },
      { label: 'Malawi', value: 'MW' },
    ];

    // Merge prioritized countries with the rest of the list
    const options = useMemo(() => {
        const filteredCountries = allCountries.filter(
        (country) =>
            !prioritizedCountries.some((prioritized) => prioritized.value === country.value)
        );
        return [...prioritizedCountries, ...filteredCountries];
    }, [allCountries]);

    const changeCountryHandler = (value) => {
        ConsoleLogEvent('info', 'country change handler label (country name): ', value.label);
        ConsoleLogEvent('info', 'country change handler value (country code): ', value.value);

        ConsoleLogEvent('info', 'country change handler value (current page): ', currentPage);

        setCountryValue(value);

        // Automatically navigate based on the current page
        if (currentPage === 'sale') {
            navigate('', { replace: true, state: { country: value } });
        } else if (currentPage === 'need') {
            navigate('supply-item', { state: { country: value } });
        }else if (currentPage === 'kit') {
            navigate('list-kit', { state: { country: value } });
        }
    }

    const handleShowSelect = () => setShowSelect(true);

    // *********************** end country name selection ***************************************

    // Check whether to show Board button on not.
    // Board button is shown only when a user has listed cattle or item for sale 
    // The purpose of the board is to show user people interested in buying or supplying 
    // User can then navigate to the board and directly contact those individuals as may want.
    
    // Fetch data and update reportData
    useEffect(() => {
        setBoardStatus(false);
        getBoardStatus();
    }, [envState.online]);

    const getBoardStatus = async () => {
        setLoading(true);  // Start loading

        const reportQuery = "/?filter=" + QUERY_FILTER_BOARD_STATUS;

        try {
            handleGetResource(
                null,
                getResource,
                envState.online,
                process.env.REACT_APP_MARKET_PLACE + reportQuery,
                setCreateNotice,
                null,
                setReportData, // This will update reportData
                null,
                false
            );
            setErrorMessage(null); // Clear any previous errors
        } catch (error) {
            // console.error('Error fetching report data:', error);
            setErrorMessage(t('error_message.failed_to_fetch'));
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        ConsoleLogEvent('info', 'report data to give me board status:', reportData);

        handleShowSelect();
    
        const returnedRecords = Array.isArray(reportData.returned_records) ? reportData.returned_records : [];
        setBoardStatus(returnedRecords[0] === '1'); // Set boardStatus based on first record
    
    }, [reportData]);
    
    return (
        <nav>
            {/* Container for all buttons */}

            <div className="overflow-hidden sm:rounded-md">
                <div className="col-span-6 lg:col-span-3">
                    <div className="w-full flex justify-center">

                        <div className="flex flex-wrap justify-start mb-2">

                            {/**  1. this button will unhide country select */}
                            <button
                                className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={forSaleDisplay}
                            >
                                {t('market_place_page.manage_face_cattle_side_cattle_button')}  {/* Show cattle that are for sale */}
                                
                            </button>

                            {/**  2. this button will unhide country select */}
                            <button
                                className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={forNeedDisplay}
                            >
                                {t('market_place_page.needed')}   {/* Check things listed as wanted for you to supply */}
                            </button>  


                            <button
                                className="max-w-[94px] mb-2  mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={forKitDisplay}
                            >
                                {t('market_place_page.advertise_kit')} 
                            </button>  




                            <button
                                className="max-w-[94px] mb-2  mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={forCloseDeal}
                            >
                                {t('market_place_page.manage')}  {/* View your own cattle or an item you listed to remove from listing - provide some information about the buyer or supplier */}
                            </button>                                                  

                            {boardStatus && (
                                <button
                                    className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                    onClick={forOpenBoard} // Adjust navigation path as needed
                                >
                                    {t('market_place_page.board')}
                                </button>
                            )}

                            <button
                                className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={forAddProduct}
                            >
                                {t('market_place_page.advertise')}  {/* Add cattle that you want to be listed for sale */}
                            </button>   

                            <button
                                className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={forRequestBuy}
                            >
                                {t('market_place_page.request')}  {/* Add an item description + pic if available of what you are looking for */}
                            </button>

                            {/** TODO: implement marketplace chat */}
                            {/* <button
                                className="max-w-[94px] mb-2 mr-2 inline-flex justify-center rounded-md border border-transparent bg-slate-600 py-2 px-4 text-xs font-semibold text-gray-200 shadow-sm hover:bg-slate-500 focus:outline-none focus:ring-2 focus:ring-slate-500 focus:ring-offset-2 uppercase whitespace-nowrap"
                                onClick={forMarketPlaceChat}
                            >
                                Chat
                            </button> */}
                            
                        </div>
                    </div>
                </div>
            </div>
            {/**  3. this select only shown when either listed sales or items is clicked otherwise hide it */}
            {showSelect && (
                <div>
                    <Select 
                        options={options} 
                        value={countryValue} 
                        onChange={changeCountryHandler} 
                        placeholder={t('manage_profile_page.filter_country_select')}
                    />
                </div>
            )}
        </nav>
    );
    
};

export { ProductNavbar };