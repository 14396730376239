import { ToastContainer } from "react-toastify";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import { useUser, useEnv } from "../contexts";
import { BottomNavigation } from "./index";
import { useLayoutEffect } from "react";

import { default as gdfIcon } from '../icons/gdf96.png';
import  { ayrshireIcon, brownSwissIcon, guernseyIcon, holsteinIcon, jerseyIcon, shorthornIcon } from  "../icons/decoPng";

import { useMemo, useRef } from 'react';


const MainMenu = () => {
    const { user } = useUser();
    const { setEnvState } = useEnv(); // Get Environment context Setter

    // for language translation
    const { t } = useTranslation();

    // Notifiy the App of this location and Header Nav requirements
    useLayoutEffect(() => {
        setEnvState(state => ({...state, whereAbout: t('buttons_navigation.button_bottom_nav_home'), backPath: "", homePath: ""}));
        return () => {
            setEnvState(state => ({...state, whereAbout: "", backPath: "", homePath: ""}));
        }
        // eslint-disable-next-line
    }, []);

    const icons = [ayrshireIcon, brownSwissIcon, guernseyIcon, holsteinIcon, jerseyIcon, shorthornIcon];
    // Ref to store the previously selected icon
    const previousIconRef = useRef(null);

    // const randomIcon = useMemo(() => icons[Math.floor(Math.random() * icons.length)], [icons]);

    // Randomly select an icon that's not the same as the previous one
    const randomIcon = useMemo(() => {
        let filteredIcons = icons;

        // Filter out the previous icon if it exists
        if (previousIconRef.current) {
        filteredIcons = icons.filter(icon => icon !== previousIconRef.current);
        }

        // Select a random icon from the filtered list
        const newIcon = filteredIcons[Math.floor(Math.random() * filteredIcons.length)];

        // Update the previous icon reference
        previousIconRef.current = newIcon;

        return newIcon;
    }, [icons]);


    return (
        <div className="mt-[100px] mb-0 p-2 md:p-0">
            <div className="grid grid-cols-6">
                {/** Left spacer */}
                <div className="col-span-1"></div>
                <div className="col-span-6 flex flex-col gap-y-4 items-center">
                    {/* Centered Image */}
                    <img
                        src={gdfIcon}
                        alt="GDF Logo"
                        className="w-24 h-24 mb-4 object-contain"
                    />

                    {/* Text Content */}
                    <p className="text-center text-gray-800 dark:text-gray-200 text-sm leading-relaxed">
                        {t('hamburger_messages.app_slogan')}
                    </p>

                    {/* Centered Image */}
                    <img
                        src={randomIcon}
                        alt="GDF Logo"
                        className=" w-full h-auto mb-4 object-contain"
                    />

                </div>

               
                <div className="down-arrow"></div>

                {/** Left spacer */}
                <div className="col-span-1"></div>
                <div>
                    {/* Toast Container */}
                    <ToastContainer
                        position="top-center"
                        autoClose={5000}
                        hideProgressBar={false}
                        closeOnClick
                        pauseOnHover
                        draggable
                        pauseOnFocusLoss
                        theme="light"
                    />
                </div>
            </div>
            {/* Bottom Navigation */}
            <BottomNavigation />
        </div>
    );
};

export { MainMenu };